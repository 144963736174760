const columnConfig = [
  {
    key: 'card_name',
    label: 'Nama Kartu',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
      fontWeight: 400,
    },
    tdStyle: {
      textTransform: 'capitalize',
      fontSize: '110px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
    },
    tdClass: 'text-red',
  },
  {
    key: 'limit_day',
    label: 'Limit Harian',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
      fontWeight: 400,
    },
    tdClass: 'text-black',
  },
  {
    key: 'balance_card',
    label: 'Saldo Kartu',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
      fontWeight: 400,
    },
    tdClass: 'text-black',
  },
  {
    key: 'balance_voucher',
    label: 'Saldo Voucher',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
      fontWeight: 400,
      textWrap: 'nowrap',
    },
    tdClass: 'text-black',
  },
  {
    key: 'balance_total',
    label: 'Total Saldo',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
      fontWeight: 400,
    },
    tdClass: 'text-black',
  },
  {
    key: 'status',
    label: 'Status',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'center',
      fontWeight: 400,
    },
    tdClass: 'text-black',
  },
  {
    key: 'more',
    label: '',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#333333',
      minWidth: '150px',
      textAlign: 'left',
      fontWeight: 400,
    },
    tdClass: 'text-black',
  },
]

export default columnConfig
